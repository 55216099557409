import React from 'react';
import styles from './o-mnie.module.sass';
import cs from 'classnames';
import Layout from '../Theme/layout';
import SEO from '../components/seo';
import Article from '../components/pages/article/Article/Article';
import BigArticleTitle from '../components/shared/texts/BigArticleTitle/BigArticleTitle';
import { graphql } from 'gatsby';

const Omnie = ({ data }) => {
  const { datoCmsAboutMe: { article} } = data;
  return (
    <Layout>
      <SEO title="O mnie"/>
      <div className={cs(styles.aboutWrap, 'wrap', 'firstSectionWrap')}>
        <BigArticleTitle className={styles.title}>O mnie</BigArticleTitle>
      </div>
      <Article data={article}/>
    </Layout>
  );
};
export const query = graphql`
  query aboutMe {
  datoCmsAboutMe {
    article {
      ... on DatoCmsParagraph {
        id
        paragraph
      }
      ... on DatoCmsTitle {
        id
        title
      }
    }
  }
}
`;

export default Omnie;
